import React from 'react';
import styles from './ModalPortfolio.module.scss';

const ModalPortfolio = ({onClick, children, onClose}) => {

    return(
        <section  className={styles.popup} onClick={onClick} >
            <div className={styles.box_close_icon}>
            <button data-test="close-button" className={styles.close_icon} type="button" aria-label="закрыть" onClick={onClose}/>
            </div>
            {children}
        </section>
    
    );
};

export default ModalPortfolio